import React from 'react';
import {ServicePerf} from './styled';
import Fade from 'react-reveal/Fade';
import {StaticImage} from 'gatsby-plugin-image';

const ServicePerformance = () => {
  return (
    <ServicePerf>
      <div className="ui-section">
        <div>
          <h1>
            Best UI/UX and performance Imagination can be brought to live with
            proper interfaces.
          </h1>

          <p>
            But when it comes to production applications performance and
            reliability are crucial factors for development. @ zegates we are
            highly regard the application quality with both aspects without
            compromising the end user experience.
          </p>
        </div>
        <div>
          <img
            alt=""
            src="/images/netlise-images.jpg"
            width={'auto'}
            height={'auto'}
          />
          {/* <StaticImage alt="" src={'/images/netlise-images.jpg'} /> */}
        </div>
      </div>
      <div className="ui-desc">
        <img src="/images/frameworks/aws-icon.png" />
        <img src="/images/frameworks/azure-icon.png" />
        <img src="/images/frameworks/google-icon.png" />
        <img src="/images/frameworks/node-icon.png" />
        <img src="/images/frameworks/react-icon.png" />
        <img src="/images/frameworks/vue-icon.png" />
        <img src="/images/frameworks/ga-icon.png" />
        <img src="/images/frameworks/java-icon.png" />
        <img src="/images/frameworks/go-icon.png" />
      </div>
    </ServicePerf>
  );
};

export default ServicePerformance;
